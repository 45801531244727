.controlItemsGrid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'link link'
    'slider01 info'
    'slider02 info';
  gap: 20px;
  padding-top: 20px;
}

.controlItemsGrid .links {
  grid-area: link;
  display: flex;
  justify-content: space-around;
}

.controlItemsGrid .info {
  grid-area: info;
  /* display: none; */
}

.controlItemsGrid .slider01 {
  grid-area: slider01;
  /* display: none; */
}

.controlItemsGrid .slider02 {
  grid-area: slider02;
  /* display: none; */
}

.videolink {
  color: #2986cc;
  font-weight: 400;
  margin-right: 20px;
  text-decoration: none;
  margin: 0;
}
