.box {
  padding: 20px;
  width: 100%;
  & > .row {
    flex-direction: row;
    display: flex;
    width: 100%;

    & > .container {
      width: 100%;
      display: flex;
      flex-direction: column;
      & > .row {
        width: 100%;
        flex-direction: row;
        display: flex;
      }
    }
  }
}

.window {
  width: 30%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto auto;
  gap: 0px 0px;
  grid-template-areas:
    "title"
    "main";
}

.title {
  padding: 5px;
  grid-area: title;
  text-align: center;
}
.main {
  grid-area: main;
  width: 100%;
  height: 100%;
}

.chart_window {
  width: 50%;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 90% 10%;
  gap: 0px 0px;
  grid-template-areas:
    "main"
    "bottom";
}

.chart_bottom {
  padding: 5px;
  grid-area: bottom;
  text-align: right;
  font-family: Roboto;
  font-size: 0.7rem;
}
.chart_main {
  grid-area: main;
  width: 100%;
  height: 100%;
}
