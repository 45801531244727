.controlElementsBox {
  display: flex;
  align-self: stretch;
  justify-content: flex-start;
  align-self: flex-start;
  width: 100%;
  gap: 20px;
  margin-top: 20px;
}

.controlElementsBox .flex-controlElement {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.controlElementsBox .flex-controlElement .controlElement-row-1 {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
}

.controlElementsBox .flex-controlElement .controlElement-row-1 .video-link {
  color: #2986cc;
  font-weight: 400;
  margin-right: 20px;
  text-decoration: none;
  margin: 0;
}

.controlElementsBox
  .flex-controlElement
  .controlElement-row-1
  .video-link:hover {
  color: #206ba3;
  font-weight: 500;
}

.controlElementsBox .flex-controlElement .controlElement-row-n {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  margin-left: 20px;
}

.controlElementsBox .flex-controlElement .controlElement-row-n .child-1 {
  flex-basis: 50%;
}
