.container {
  margin: 20px;
  width: fit-content;
}

.container * {
  font-family: Roboto;
}

.container img {
  height: 3rem;
  margin-bottom: 20px;
}

.container .column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container .column .links {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  flex-wrap: wrap;

  gap: 20px;
  width: inherit;
  padding-bottom: 10px;
}

.container .column .links a {
  min-width: 350px;
}

.container .column .links .note {
  width: 350px;
  font-size: 0.7rem;
  line-height: 0.7rem;
  color: #969696;
  margin-top: 0.4rem;
}

.container .column .links .warning {
  width: 350px;
  font-size: 0.7rem;
  line-height: 0.7rem;
  color: red;
  margin-top: 0.4rem;
}

.container .column .links .note span a {
  font-weight: bold;
  color: #969696;
}

.logout {
  /* display: absolute; */
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 900px) {
  .container {
    margin: 20px auto;
  }
}
