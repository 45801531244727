.theoplayer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 47vw;
  /* width: 100%;
  height: 100%; */
  /* gap: 0px 0px; */
  /* grid-auto-flow: row; */
  /* min-height: 0;
  min-width: 0; */
  /* overflow: hidden; */
}

.theoplayer-player {
  grid-area: 1 / 1 / 2 / 2;
  z-index: 1;
}

.theoplayer-wallclock {
  grid-area: 1 / 1 / 2 / 2;
  z-index: 2;
  align-self: end;
  justify-self: center;
  color: white;
  font-size: 1.5vw;
  font-family: 'Helvetica Neue';
  font-weight: 300;
  letter-spacing: 1px;
  margin: 10px;
  /* padding: 10px; */
}

/* @media (max-width: 1600px) {
  .theoplayer-wallclock {
    font-size: 3vw;
    margin: 10px;
  }
} */

/* @media (max-width: 700px) {
  .theoplayer-wallclock {
    font-size: 3vw;
    margin: 10px;
    padding: 0px
  }
} */

canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  display: none;
}
