.dashreference {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 47vw;
  height: 100%;
}

.dashreference-player {
  grid-area: 1 / 1 / 2 / 2;
  z-index: 1;
}

.dashreference-wallclock {
  grid-area: 1 / 1 / 2 / 2;
  z-index: 2;
  align-self: end;
  justify-self: center;
  color: white;
  font-size: 1.5vw;
  font-family: 'Helvetica Neue';
  font-weight: 300;
  letter-spacing: 1px;
  margin: 10px;
}

/* @media (max-width: 1600px) {
  .theoplayer-wallclock {
    font-size: 3vw;
  }
} */
