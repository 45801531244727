.flex-title-container {
  display: flex;
  flex-direction: column;
  margin: 15px;
  /* width: 60vw; */
}

.flex-title-container > img {
  /* width: 35vw; */
  /* height: 20vw; */
  width: 45vw;
}

.flex-title-container .row {
  display: flex;
  height: 10rem;
}

.flex-title-container .row .column-1 {
  color: #fff;
  background: #000;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-title-container .row .column-1 > h2 {
  font-size: 1rem;
  text-align: center;
  margin: 0;
  padding: 0 5px;
}

.flex-title-container .row .column-1 > img {
  height: 5rem;
}

.flex-title-container .row .column-2 {
  flex: 3;
  background: #bcbcbc;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-title-container .row .column-2 > h2 {
  font-size: 1.7rem;
}

@media (max-width: 1600px) {
  .flex-title-container > img {
    width: 90vw;
  }
}
