.flex-container {
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  /* justify-content: flex-start; */
  align-items: center;
  /* gap: 20px; */
  /* margin: 15px; */
  /* width: fit-content;
  height: fit-content; */
}

.flex-container .flex-playerPage {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  /* width: 90vw; */
  /* height: 70vh; */
  /* width: fit-content;
  height: fit-content; */
}

.flex-container .flex-playerPage .flex-playerPage-row {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* width: 100%; */
  /* width: 35vw; */
}

.flex-container .flex-playerPage .flex-playerPage-row > h2 {
  font-size: 1.2rem;
}

/* .flex-container .flex-playerPage .flex-playerPage-row > div {
  width: 100%;
} */

/* @media (max-width: 1600px) {
  .flex-container .flex-playerPage {
    width: 90vw;
  }
} */
