* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: Roboto;
  font-size: max(0.8rem, 0.7vw);
}

.container {
  margin: 20px;
  display: grid;
  grid-template-areas:
    'logos'
    'links'
    'main';
}

.container .companyLogos {
  grid-template-areas: logos;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  gap: 3rem;
  margin-bottom: 20px;
}

.container .companyLogos > img {
  height: 3rem;
}

.container .links {
  grid-template-areas: links;
}

.container .main {
  grid-template-areas: main;
  width: fit-content;
}

.item {
  background: magenta;
}

.container .main .grid {
  display: grid;
  grid-template-areas:
    'player'
    'control';
}

.container .main .grid .player {
  grid-area: player;
}

.container .main .grid .control {
  grid-area: control;
}

.container .greybox {
  width: 47vw;
  height: calc(47vw * 9 / 16);
  background: #ccc;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.container .tvbox {
  color: #fff;
}

@media (max-width: 768px) {
  html,
  body {
    font-size: 1.1vw;
  }

  .container {
    margin: 20px auto;
  }
  .container .companyLogos > img {
    height: 3rem;
  }
}
