.container {
  margin: 50px 0px 0px 50px;
  width: 40vw;
}

.saveButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.passwordInput {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
}
