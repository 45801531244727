* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: Roboto;
  font-size: 1rem;
}

.container {
  margin: 20px 20px;
}

.container .flex-frontPage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* width: fit-content; */
}

.container .flex-frontPage > img {
  height: 3rem;
  margin-bottom: 10px;
}

.container .flex-frontPage .content {
  /* width: 100%;
  height: 800px; */
}

@media (max-width: 850px) {
  html,
  body {
  }

  .container {
    margin: 20px 10px;
    width: fit-content;
  }
}
