.container {
  margin: 50px 0px 0px 50px;
  width: 50vw;
}

.companyLogos {
  grid-template-areas: logos;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  gap: 3rem;
  margin-bottom: 20px;
}

.companyLogos > img {
  height: 3rem;
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
}

.buttonFixLinks {
  margin-top: 30px;
}
